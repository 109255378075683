<template>
  <b-card>
    <div class="d-flex">
      <h2><strong>Adicione o produto</strong></h2>
    </div>
    <hr>
    <div>
      <h4>Dados Gerais:</h4>
    </div>
    <b-row class="mt-1">
      <b-col md="2">
        <b-form-group label="Codigo:" label-for="code">
          <b-form-input id="code" v-model="products.code" placeholder="Codigo do Produto" />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Nome: *" label-for="name">
          <b-form-input id="name" v-model="products.name" placeholder="Nome do Produto" />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Marca: *" label-for="brand">
          <b-form-input id="brand" v-model="products.brand" placeholder="Marca" />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Preparação (Em dias):" label-for="preparationDays">
          <b-form-input id="preparationDays" v-model="products.preparationDays" placeholder="Digite o valor em dias" />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Garantia (Em messes):" label-for="warranty">
          <b-form-input id="warranty" v-model="products.warranty" placeholder="Digite o valor em meses" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2">
        <b-form-group label="Situação" label-for="situation">
          <v-select v-model="products.situation" label="name" :options="opSituation" placeholder="Situação" />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Tipo" label-for="text">
          <v-select v-model="products.type" label="name" :options="opType" placeholder="Tipo" />
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <div>
      <h4>Estoque:</h4>
    </div>
    <b-row>
      <b-col md="2">
        <b-form-group label="Estoque mínimo:" label-for="minimumStock">
          <b-form-input id="minimumStock" v-model="products.minimumStock" placeholder="Estoque mínimo" />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Estoque atual:" label-for="quantity">
          <b-form-input id="quantity" v-model="products.quantity" placeholder="Estoque atual" />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Estoque máximo:" label-for="maximumStock">
          <b-form-input id="maximumStock" v-model="products.maximumStock" placeholder="Estoque máximo" />
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <div>
      <h4>Pesos e Dimensões:</h4>
    </div>
    <b-row>
      <b-col md="3">
        <b-form-group label="Peso liquido:" label-for="netWeight">
          <b-form-input id="netWeight" v-model="products.netWeight" placeholder="Peso liquido" />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Peso Bruto:" label-for="grossWeight">
          <b-form-input id="grossWeight" v-model="products.grossWeight" placeholder="Peso Bruto" />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Unidade do produto" label-for="unity">
          <v-select v-model="products.unity" label="name" :options="opUnity" placeholder="Unidade" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3">
        <b-form-group label="Altura do pacote:" label-for="packHeigth">
          <b-form-input id="packHeigth" v-model="products.packHeigth" placeholder="Altura do pacote" />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Profundidade do pacote:" label-for="packLength">
          <b-form-input id="packLength" v-model="products.packLength" placeholder="Peso Bruto" />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Largura do pacote:" label-for="packWidth">
          <b-form-input id="packWidth" v-model="products.packWidth" placeholder="Largura do pacote" />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Diâmetro do pacote:" label-for="packDiameter">
          <b-form-input id="packDiameter" v-model="products.packDiameter" placeholder="Diâmetro do pacote" />
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <div>
      <h4>Valores:</h4>
    </div>
    <b-row>
      <b-col md="2">
        <b-form-group label="Preço de custo:" label-for="costPrice">
          <b-form-input id="costPrice" v-model="products.costPrice" v-money="money" placeholder="Preço de custo" />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Media do custo:" label-for="averageCostPrice">
          <b-form-input id="averageCostPrice" v-model="products.averageCostPrice" v-money="money"
            placeholder="Media do custo" />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Valor" label-for="price">
          <b-form-input id="price" v-model="products.price" v-money="money" placeholder="Valor" />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Valor Promocional:" label-for="promotionalPrice">
          <b-form-input id="promotionalPrice" v-model="products.promotionalPrice" v-money="money"
            placeholder="Valor Promocional" />
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <div>
      <h4>Tributações:</h4>
    </div>
    <b-row>
      <b-col md="2">
        <b-form-group label="Origem:" label-for="origim">
          <b-form-input id="origim" v-model="products.origim" placeholder="Origem" />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="NCM:" label-for="ncm">
          <b-form-input id="ncm" v-model="products.ncm" placeholder="NCM" />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group label="Cest" label-for="cest">
          <b-form-input id="cest" v-model="products.cest" placeholder="Cest" />
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <div>
      <h4>Adicionais:</h4>
    </div>
    <b-row>
      <b-col md="12">
        <b-form-group label="Observação:" label-for="obs">
          <b-form-input id="obs" v-model="products.obs" placeholder="Obs" />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group label="Descrição:" label-for="complementeDescription">
          <b-form-textarea id="complementeDescription" v-model="products.complementeDescription"
            placeholder="Descrição complementar" rows="3" />
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col class="mt-1" md="6">
        <b-button variant="success" block @click="SalvarDados()">
          Salvar Produto
        </b-button>
      </b-col>
      <b-col class="mt-1" md="6">
        <b-button variant="success" block @click="limpacampos()">
          Limpar Campos
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { VMoney } from 'v-money'

import { BFormInput } from 'bootstrap-vue'

export default {
  name: 'Index',
  directives: { money: VMoney },
  components: {
    BFormInput,
  },
  data() {
    return {
      products: {},
      opSituation: [
        {
          name: 'Ativo',
          value: 'A',
        },
        {
          name: 'Inativo',
          value: 'I',
        },
      ],
      opType: [
        {
          name: 'Produto',
          value: 'P',
        },
        {
          name: 'Serviço',
          value: 'S',
        },
      ],
      opUnity: [
        {
          name: 'Peça',
          value: 'PC',
        },
        {
          name: 'Kg',
          value: 'KG',
        },
        {
          name: 'Litro',
          value: 'L',
        },
      ],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
    }
  },
  async created() {
    this.$loading(false)
  },
  methods: {
    limpacampos() {
      this.products = {}
    },
    voltapagina() {
      this.$router.push('/produtos')
    },
    async SalvarDados() {
      this.$loading(true)
      if (!this.products.name) {
        this.$loading(false)
        return this.$toast.error('Preencha o Nome')
      }
      if (!this.products.type) {
        this.$loading(false)
        return this.$toast.error('Preencha o Tipo')
      }
      if (!this.products.situation) {
        this.$loading(false)
        return this.$toast.error('Preencha a Situação')
      }
      if (!this.products.unity) {
        this.$loading(false)
        return this.$toast.error('Preencha a Unidade')
      }
      if (!this.products.price) {
        this.$loading(false)
        return this.$toast.error('Preencha o Valor')
      }
      // eslint-disable-next-line no-unused-vars
      await this.$http.post('products/create', this.products).then(resp => {
        this.$loading(false)
        if (resp.data.success) {
          this.$router.push('/produtos')
          this.$toast.success('Produto criado Com Sucesso')
        } else {
          this.$toast.error('Erro ao criar Produto')
        }
      })
      return true
    },
  },
}

</script>

<style scoped>
</style>
